import axios from "axios";

interface FormData {
  interestedIn:
    | "ECommerce Solutions"
    | "App Development"
    | "User Experience"
    | "Product Design & Engineering"
    | "Whatsapp Business Solution";

  name: string;
  email: string;
  phone: string;
  aboutYourProject: string;
}

export const sendPostRequest = async (formData: FormData) => {
  const url = "https://d1a4tbx91ajujb.cloudfront.net/api/sendmail";

  const headers = {
    "Content-Type": "application/json",
    authid: process.env.REACT_APP_AUTH_ID,
  };

  const res = await axios.post(url, formData, {
    headers: headers,
  });

  return res.data;
};
