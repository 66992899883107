export default () => {
  return (
    <div className="flex justify-center">
      <div className="container text-white px-4">
        <h1 className="text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold mt-20 text-[#D4F049]">
          Outworld Concepts Privacy Policy
        </h1>

        <p className="mb-8">Last Updated: Nov 27, 2023</p>

        <p className="mb-8 text-lg">
          Thank you for using Outworld Concepts! This Privacy Policy outlines
          how we collect, use, disclose, and manage your information when you
          use our mobile application (Outworld Concepts, the "App").
        </p>

        <p className="mb-8 text-lg">
          By using the App, you agree to the terms and conditions of this
          Privacy Policy. If you do not agree with the practices described in
          this policy, please do not use the App.
        </p>

        <h2 className="text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold mb-4">
          1. Information We Collect
        </h2>

        <h3 className="mb-2 text-lg font-bold">1.1 Personal Information</h3>
        <p className="mb-8 text-lg">
          We may collect personal information that you voluntarily provide to
          us, such as your name, email address, and any other information you
          choose to share when using the App.
        </p>

        <h3 className="mb-2 text-lg font-bold">1.2 Usage Information</h3>
        <p className="mb-8 text-lg">
          We collect information about how you use the App, including your
          interactions, preferences, and settings. This may include data such as
          your device type, operating system, IP address, and mobile network
          information.
        </p>

        <h3 className="mb-2 text-lg font-bold">1.3 Location Information</h3>
        <p className="mb-8 text-lg">
          If you enable location services, we may collect and process
          information about your device's location to provide you with
          location-based services. You can disable location services at any time
          through your device settings.
        </p>

        <h2 className="text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold mb-4">
          2. How We Use Your Information
        </h2>
        <p className="mb-8 text-lg">
          We use the collected information for the following purposes:
        </p>

        <h2 className="text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold mb-4">
          3. Sharing Your Information
        </h2>
        <p className="mb-8 text-lg">
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your consent, except as described below:
        </p>

        <h2 className="text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold mb-4">
          4. Security
        </h2>
        <p className="mb-8 text-lg">
          We take reasonable measures to protect your information from
          unauthorized access, disclosure, alteration, and destruction. However,
          no method of transmission over the internet or electronic storage is
          100% secure, and we cannot guarantee absolute security.
        </p>

        <h2 className="text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold mb-4">
          5. Your Choices
        </h2>
        <p className="mb-8 text-lg">
          You have the right to access, update, and delete your personal
          information. You can also opt-out of receiving promotional
          communications from us. To exercise these rights, please contact us at
          support@outworldconcepts.com.
        </p>

        <h2 className="text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold mb-4">
          6. Changes to the Privacy Policy
        </h2>
        <p className="mb-8 text-lg">
          We may update this Privacy Policy from time to time, and any changes
          will be effective immediately upon posting. We encourage you to review
          this Privacy Policy regularly.
        </p>

        <h2 className="text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold mb-4">
          7. Contact Us
        </h2>
        <p className="mb-8 text-lg">
          If you have any questions, concerns, or requests regarding this
          Privacy Policy, please contact us at support@outworldconcepts.com.
        </p>

        <p className="mb-8 text-lg">
          Thank you for trusting Outworld Concepts with your information.
        </p>
      </div>
    </div>
  );
};
