import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { sendPostRequest } from "../lib/api";
import FormFooter from "./FormFooter";
import TextInput from "./TextInput";

interface FormData {
  interestedIn:
    | "ECommerce Solutions"
    | "App Development"
    | "User Experience"
    | "Product Design & Engineering"
    | "Whatsapp Business Solution";
  name: string;
  email: string;
  phone: string;
  aboutYourProject: string;
}

const interestedInArray = [
  "ECommerce Solutions",
  "App Development",
  "User Experience",
  "Product Design & Engineering",
  "Whatsapp Business Solution",
];

const Form = () => {
  const [formData, setFormData] = useState<FormData>({
    interestedIn: "ECommerce Solutions",
    name: "",
    email: "",
    phone: "",
    aboutYourProject: "",
  });

  const handleInputChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      formData.interestedIn.trim() === "" ||
      formData.name.trim() === "" ||
      formData.email.trim() === "" ||
      formData.aboutYourProject.trim() === ""
    ) {
      return toast.error("Please fill in all required fields");
    }
    await sendPostRequest(formData);

    setFormData({
      aboutYourProject: "",
      email: "",
      interestedIn: "ECommerce Solutions",
      name: "",
      phone: "",
    });

    toast.success(
      "Thank you for reaching out to us with your inquiry. We appreciate the opportunity to assist you."
    );
  };

  return (
    <form
      onSubmit={(event) => {
        handleSubmit(event).catch((e: Error) => toast.error(e.message));
      }}
    >
      <ToastContainer />
      <div className="mt-[200px] bg-white rounded-xl p-[30px]">
        <div className="font-nunito font-semibold text-[35px] leading-[47.74px]">
          <h1>
            Let's Have a <span className="text-[#ED3D67]">Chat!</span>
          </h1>
          <hr className="bg-black w-full h-[2px] mt-3" />
        </div>
        <div className="flex flex-col md:flex-row  justify-around mt-[25px]">
          <div className="w-full md:w-[50%]">
            <div className={`flex flex-col w-full mt-[13px] `}>
              <h1 className="text-pink text-sm">You are interested in</h1>
              <select
                className="text-black rounded-none w-full text-sm h-10 flex px-2 hover:border-[#ED3D67] focus:outline-none focus:border-[#ED3D67]  border-b-2"
                name="interestedIn"
                value={formData.interestedIn}
                onChange={handleSelectChange}
              >
                {interestedInArray.map((each) => (
                  <option key={each} value={each}>
                    {each}
                  </option>
                ))}
              </select>
            </div>
            <TextInput
              title="Name *"
              type="text"
              name="name"
              value={formData.name}
              onChange={(value) => handleInputChange("name", value)}
            />
            <TextInput
              title="Email *"
              type="email"
              name="email"
              value={formData.email}
              onChange={(value) => handleInputChange("email", value)}
            />
            <TextInput
              title="Phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={(value) => handleInputChange("phone", value)}
            />
            <TextInput
              title="Tell us your project *"
              type="text"
              name="projectDetails"
              value={formData.aboutYourProject}
              onChange={(value) => handleInputChange("aboutYourProject", value)}
            />

            <button
              type="submit"
              className="flex justify-start mt-9 border-[1.5px] border-black p-2"
            >
              <span className=" uppercase text-[15px] inline fontInter600">
                SEND
                <div className="inline h-[100%]">
                  <img
                    className="h-auto w-8 ml-7 inline"
                    src="images/arrow4.png"
                    alt=""
                  />
                </div>
              </span>
            </button>
          </div>
          <FormFooter />
        </div>
      </div>
    </form>
  );
};

export default Form;
