function Work() {
  return (
    <div className="container mx-auto px-6 py-3 bg-black text-[#FFFFFF]">
      {/* HERO SECTION */}
      <div className="container mx-auto flex justify-center px-6 mb-12 ">
        <h1 className="md:mr-0  text-[#FFFFFF] lg:mr-0  justify-center w-11/12 lg:mt-30 mt-10 text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold  lg:leading-tight text-center">
          We make
          <span className="text-[#D4F049]"> Mobile Apps, Web Apps </span>
          and<span className="text-[#D4F049]"> AI Bots </span>
        </h1>
      </div>

      {/* work */}
      {/* Aladdin */}
      <div
        id="aladdin"
        className="lg:flex lg:flex-row flex-col py-[30px] justify-between"
      >
        <div className="bg-[#141414] rounded-[20px] py-[25px] sm:px-[50px]  px-[30px] lg:w-5/12">
          <h2 className="fontNunito600 text-[50px]">Aladdin</h2>
          <p className="fontNunito500 text-[18px] py-[25px]">
            A marketplace and customer community for telecom services in the
            UAE. The digital platform helps customers to discover, compare and
            make informed decisions about mobile, home services and plans in the
            UAE. Customers can rate and share their experiences in an AI-Powered
            customer community. Authorized Sellers including physical retailers
            can plug in to the marketplace to get online customer traffic.
          </p>
          <div className="py-[35px] fontInter500">
            <h3 className="text-[#D4F049]">DELIVERABLES</h3>
            <ul className="list-disc pl-[18px]">
              <li>Mobile App Design</li>
              <li>Mobile App Development</li>
              <li>AI-Powered Bot</li>
            </ul>
          </div>
          <div className="py-[15px] fontInter500">
            <h3 className="text-[#D4F049]">TECH STACK</h3>
            <ul className="list-disc pl-[18px]">
              <li>React Native</li>
              <li>React Js</li>
              <li>Node Js</li>
              <li>Hasura</li>
              <li>Postgres</li>
            </ul>
          </div>
        </div>
        <div className="flex justify-end lg:w-7/12  py-[20px] lg:py-[0px]">
          <img className="lg:w-[95%]" src="images/aladdin.png" alt="" />
        </div>
      </div>
      {/* Hotline*/}
      <div
        id="hotline"
        className="lg:flex py-[30px] justify-between lg:flex-row flex flex-col-reverse"
      >
        <div className="lg:w-7/12  py-[20px] lg:py-[0px]">
          <img className="lg:w-[95%]" src="images/hotline.png" alt="" />
        </div>
        <div className="bg-[#141414] rounded-[20px] py-[25px] sm:px-[50px]  px-[30px]  lg:w-5/12">
          <h2 className="fontNunito600 text-[50px]">Hotline</h2>
          <p className="fontNunito500 text-[18px] py-[25px]">
            Hotline is a place to connect with professionals/influencers across
            the globe. App comes with chat, audio call and video calls to allow
            1:1 conversations.
          </p>
          <div className=" py-[35px] fontInter500">
            <h3 className="text-[#D4F049]">DELIVERABLES</h3>
            <ul className="list-disc pl-[18px]">
              <li>Mobile App Development</li>
            </ul>
          </div>
          <div className=" py-[15px] fontInter500">
            <h3 className="text-[#D4F049]">TECH STACK</h3>
            <ul className="list-disc pl-[18px]">
              <li>React Native</li>
              <li>Node Js</li>
              <li>Hasura</li>
              <li>Postgres</li>
              <li>Agora</li>
            </ul>
          </div>
        </div>
      </div>
      {/* D’Hybrid */}
      <div
        id="dhybrid"
        className="lg:flex lg:flex-row flex-col py-[30px] justify-between"
      >
        <div className="bg-[#141414] rounded-[20px] py-[25px] sm:px-[50px]  px-[30px]  lg:w-5/12">
          <h2 className="fontNunito600 text-[50px]">D’Hybrid</h2>
          <p className="fontNunito500 text-[18px] py-[25px]">
            D'hybrid is India's first men's-only fashion and grooming app,
            offering global mainstream brands, local hype labels and abundant
            personal care products to shop.
          </p>
          <div className="py-[35px] fontInter500">
            <h3 className="text-[#D4F049]">DELIVERABLES</h3>
            <ul className="list-disc pl-[18px]">
              <li>Mobile App Development</li>
            </ul>
          </div>
          <div className="py-[15px] fontInter500">
            <h3 className="text-[#D4F049]">TECH STACK</h3>
            <ul className="list-disc pl-[18px]">
              <li>React Native</li>
            </ul>
          </div>
        </div>
        <div className="flex justify-end lg:w-7/12  py-[20px] lg:py-[0px]">
          <img className="lg:w-[95%]" src="images/dhybrid.png" alt="" />
        </div>
      </div>
      {/* Freshr*/}
      <div
        id="freshr"
        className="lg:flex py-[30px] justify-between lg:flex-row flex flex-col-reverse"
      >
        <div className="lg:w-7/12  py-[20px] lg:py-[0px]">
          <img className="lg:w-[95%]" src="images/freshr.png" alt="" />
        </div>
        <div className="bg-[#141414] rounded-[20px] py-[25px] sm:px-[50px]  px-[30px]  lg:w-5/12">
          <h2 className="fontNunito600 text-[50px]">Freshr</h2>
          <p className="fontNunito500 text-[18px] py-[25px]">
            FreshR is a B2B-focused meat and seafood delivery startup. It's an
            ultimate platform for hotels, restaurants and Cafes to make
            wholesale purchases of quality fish and meat products in India.
          </p>
          <div className=" py-[35px] fontInter500">
            <h3 className="text-[#D4F049]">DELIVERABLES</h3>
            <ul className="list-disc pl-[18px]">
              <li>Mobile App Development</li>
            </ul>
          </div>
          <div className=" py-[15px] fontInter500">
            <h3 className="text-[#D4F049]">TECH STACK</h3>
            <ul className="list-disc pl-[18px]">
              <li>React Native</li>
            </ul>
          </div>
        </div>
      </div>
      {/* Curbwaste */}
      <div
        id="curbwaste"
        className="lg:flex lg:flex-row flex-col py-[30px] justify-between"
      >
        <div className="bg-[#141414] rounded-[20px] py-[25px] sm:px-[50px]  px-[30px]  lg:w-5/12">
          <h2 className="fontNunito600 text-[50px]">Curbwaste</h2>
          <p className="fontNunito500 text-[18px] py-[25px]">
            A software for haulers by haulers. CurbWaste revolutionizes the
            waste management ecosystem through its cloud-based and data-driven
            cleantech platform.
          </p>
          <div className="py-[35px] fontInter500">
            <h3 className="text-[#D4F049]">DELIVERABLES</h3>
            <ul className="list-disc pl-[18px]">
              <li>App Development</li>
              <li>Consultation UX</li>
              <li>Design</li>
            </ul>
          </div>
          <div className="py-[15px] fontInter500">
            <h3 className="text-[#D4F049]">TECH STACK</h3>
            <ul className="list-disc pl-[18px]">
              <li>React Native</li>
              <li> Node Js</li>
            </ul>
          </div>
        </div>
        <div className="flex justify-end lg:w-7/12  py-[20px] lg:py-[0px]">
          <img className="lg:w-[95%]" src="images/curbwaste.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Work;
