const Footer = () => {
  return (
    <div className="flex bg-black text-white justify-center">
      <h1 className="fontNunito600 text-[18px] py-[15px]">
        Follow Us On:&nbsp;
        <a
          className="underline decoration-[#919191]"
          target="_blank"
          href="https://www.linkedin.com/company/outworld-concepts"
        >
          Linked In
        </a>
      </h1>
    </div>
  );
};

export default Footer;
