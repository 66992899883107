import { useAnimation } from "framer-motion";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import Client from "../components/Client";
import { FlipWords } from "../components/FlipWords";

const words = [
  "Build Mobile App for your website",
  "Create a Large Scale Blog",
  "Build an E-commerce Website and App",
  "Migrate Wordpress to Headless CMS",
  "Create Generative AI apps",
  "Modernize your legacy code",
];

function Home() {
  const [once, setOnce] = useState(true);
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && once) {
      control.start("visible");
      setOnce(false);
    }
  }, [control, inView]);

  return (
    <>
      {/* HERO SECTION */}
      <div className="container h-[100px] mx-auto px-2 sm:mb-[65px] mb-[80px]">
        <h1 className="md:mr-0  text-[#FFFFFF] lg:mr-0 lg:mt-30 mt-20 text-[24px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold  lg:leading-tight text-center">
          With us, you can
          <FlipWords
            words={words}
            textClassName="text-[#D4F049] text-[30px] md:text-[35px] lg:text-[45px]"
            duration={3000}
          />
          10x faster
        </h1>
      </div>

      {/* <div className="h-[40rem] flex justify-center items-center px-4">
        <div className="text-4xl mx-auto font-normal text-neutral-600 dark:text-neutral-400">
          Build
          <FlipWords words={words} /> <br />
          websites with Aceternity UI
        </div>
      </div> */}

      {/* ABOUT US SECTION */}
      <div className="container m-auto px-6 py-3 sm:mb-[130px] mb-[50px]">
        <div className="relative text-center overflow-hidden">
          <img
            className="mx-auto lg:w-[400px] lg:h-[400px] md:w-[350px] md:h-[350px] w-[200px] h-[200px] "
            src="images/heroImage.gif"
          />
          <div
            className="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
  px-6 py-4"
          >
            <Link to="/work">
              <div className="md:text-[40px] inline-block fontInter700 text-[22px] lg:text-[60px] text-[#FFFFFF]">
                OUTWORLD CONCEPTS
                <div className="flex  sm:justify-end justify-center">
                  <img
                    className="  m-0 lg:w-[130px] md:w-[90px] w-[70px]"
                    src="images/arrow1.png"
                    alt="lol"
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/*Spiral Section*/}
      <div className="bg-white">
        <div className="container md:mt-[30px] mt-[25px]  m-auto px-6 py-3 text-[#000]">
          <div className="">
            <h2 className="fontNunito600 lg:text-[28px] sm:text-[28px] text-[24px] py-[30px]  ">
              Got a brilliant startup idea <br />
              that you think can be a&nbsp;
              <span className="text-[#ED3D67]">disruptive product?</span>
            </h2>
          </div>
          <hr className="text-[#D5D5E1] " />
          <div className="">
            <h2 className="fontNunito600 lg:text-[28px] sm:text-[28px] text-[24px] py-[30px] ">
              Got a new age tech challenge
              <br /> or need for&nbsp;
              <span className="text-[#ED3D67]">business automation?</span>
            </h2>
          </div>
          <hr className="text-[#D5D5E1] " />
          <div className="">
            <h2 className="fontNunito600 lg:text-[28px] sm:text-[28px] text-[24px] py-[30px]">
              Looking for a simple&nbsp;
              <span className="text-[#ED3D67]">digital product</span> <br />
              that can ease your work?
            </h2>
          </div>
          <hr className="text-[#D5D5E1] " />
          <div className="py-[30px]">
            <h2 className="fontNunito800 lg:text-[28px] sm:text-[28px] text-[24px] py-[25px]">
              We will help you solve your challenges using world class tech
              solutions and agile design.
            </h2>
            <h3 className="fontNunito600 lg:text-[22px] sm:text-[20px] text-[20px] ">
              Our expertise lies in creating green field projects, handholding
              clients on their journey of evolving an idea into a minimal viable
              digital product.
            </h3>
          </div>
          <Link to="/work">
            <div
              className="flex justify-end
       align-middle pb-[20px]	mt-5 "
            >
              <span className="uppercase lg:text-[20px] md:text-[15px] inline text-[black] fontInter600">
                View Our Work
                <div className="inline h-[100%]">
                  <img
                    className="h-auto lg:w-max-12  ml-7 inline"
                    src="images/arrow3.png"
                    alt=""
                  />
                </div>
              </span>
            </div>
          </Link>
        </div>
      </div>
      {/* CLIENTS SECTION */}
      <Client />

      {/* CULTURE SECTION */}
      <div className=" bg-black">
        <div className=" container mx-auto text-[#FFFFFF]  px-6 py-3  my-[40px]">
          <div className="flex flex-col">
            <div>
              <h2 className="lg:text-[50px] text-[40px] lg:leading-[60px] leading-[50px] sm:leading-[50px] mb-[40px] md:text-[40px] text-[#D4F049] fontNunito600">
                Our <br></br>Culture
              </h2>
            </div>
            <div className="flex md:flex-row flex-col items-center">
              {/* <motion.div
                className='box'
                ref={ref}
                variants={boxVariant}
                initial='hidden'
                animate={control}
              > */}
              {/* </motion.div> */}

              <p className="lg:text-[18px] pr-[20px] md:text-[15px] fontNunito600">
                As we are slowly growing, we strive to create a culture that
                empowers humble, passionate and self organising workforce.
                <br />
                <br /> We are a tech at heart company. But at the same time we
                believe our culture is our soul. And our people are at the
                center of all this. <br />
                <br />
                Want to be a part of an awesome journey of a newborn software
                solutions company?
                <br />
                <br />
              </p>
              <div>
                <img className="w-[250px]" src="images/culture.png" />
              </div>
              <br />
              <br />
            </div>
            <Link to="/contact">
              <div className="flex justify-start	">
                <span className=" uppercase lg:text-[20px] md:text-[15px] inline text-[#FFFFFF]  fontInter600">
                  WORK WITH US
                  <div className="inline h-[100%]">
                    <img
                      className="h-auto lg:w-max-12  ml-7 inline"
                      src="images/arrow3.png"
                      alt=""
                    />
                  </div>
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* SIGN OFF SECTION */}
      <div className="m-auto pt-16 bg-white-pattern">
        <Link to="/contact">
          <div className="container m-auto px-6 py-3   fontNunito600">
            <div className="justify-center text-center">
              <span className="pt-[50px]  text-[#000000]  lg:text-[32px] sm:text-[30px] text-[28px] inline   fontNunito600">
                Drop us a message or Just say Hi!
                <div className="inline h-[100%]">
                  <img
                    className="h-auto lg:w-max-12  ml-7 inline"
                    src="images/arrow4.png"
                    alt=""
                  />
                </div>
              </span>
            </div>
          </div>
        </Link>
        <img
          style={{ marginBottom: "-180px" }}
          className="mx-auto py-12 "
          src="images/signOff.png"
        />
      </div>
      <div className=" w-[100%] flex items-center flex-col justify-center bg-black text-white pt-40 pb-20">
        <h2 className="w-[100%] lg:text-[32px] md:text-[30px] sm:text-[28px]  text-[28px] fontNunito600 pt-[150px] pb-[25px] py-auto text-center ">
          Didnt like anything above? Here.. Enjoy this comic strip!
        </h2>
        <img src="images/comic.png" alt="" />
      </div>
    </>
  );
}

export default Home;
