import { Link } from "react-router-dom";

function Navbar() {
  return (
    <div className=" container mx-auto  px-6 ">
      <nav className="w-full text-[#FFFFFF]  fontInter800">
        <div className="md:px-2  lg:px-0  flex  gap-3 justify-between mx-auto lg:max-w-7xl md:items-center md:grid  md:grid-cols-2 md:gap-3">
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <Link to="/">
              <img
                src="images/logo.png"
                className="w-10"
                alt="Outworld Concepts"
              />
            </Link>
          </div>
          {/* for responsive ${
            navbar ? "block" : "hidden"
            }` */}
          {/* <div
          className={` items-center pb-3 mt-8 md:block md:pb-0 md:mt-0 hidden `}
        >
          <div className='justify-between'>
            <div className='hidden justify-center w-full lg:items-center lg:flex '>
              <ul className='space-y-10  tracking-[2px] font-medium text-[#FFFFFF] lg:pb-0  lg:items-center lg:flex lg:space-y-0 md:flex uppercase fontInter800  md:items-center  md:space-y-0'>
                <li>
                  <Link to='/who' className=' block md:px-6'>
                    <span>Who</span>
                  </Link>
                </li>
                <li>
                  <Link to='/what' className='block md:px-6'>
                    <span>What</span>
                  </Link>
                </li>
                <li>
                  <Link to='/work' className='block md:px-6'>
                    <span>Work</span>
                  </Link>
                </li>
                <li className='lg:hidden md:hidden'>
                  <Link to='/contact' className='block md:px-6'>
                    <span>Talk to us</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
          <div className=" items-center justify-end">
            {/* <div className='md:hidden flex items-center h-[100%]'>
            {navbar ? (
              <button
                aria-label='humburger'
                id='hamburger'
                className='relative w-10 h-10 -mr-2 lg:hidden'
                onClick={() => {
                  setNavbar(!navbar);
                }}
              >
                <div
                  aria-hidden='true'
                  id='line'
                  className='inset-0 w-6 h-0.5 m-auto rounded bg-white transtion duration-300'
                />
                <div
                  aria-hidden='true'
                  id='line2'
                  className='inset-0 w-6 h-0.5 mt-2 m-auto rounded bg-white transtion duration-300'
                />
              </button>
            ) : (
              <button
                aria-label='humburger'
                id='hamburger'
                className='relative w-10 h-10 -mr-2 lg:hidden'
                onClick={() => {
                  setNavbar(!navbar);
                }}
              >
                <div
                  aria-hidden='true'
                  id='line'
                  className='inset-0 w-6 h-0.5 m-auto rounded bg-white transtion duration-300'
                />
                <div
                  aria-hidden='true'
                  id='line2'
                  className='inset-0 w-6 h-0.5 mt-2 m-auto rounded bg-white transtion duration-300'
                />
              </button>
            )}
          </div> */}
            <div className=" sm:flex  justify-end py-[15px] lg:py-[0px]">
              <Link
                className="uppercase border-2 tracking-[2px] text-[#FFFFFF] border-[#D4F049] items-center py-[6px] px-[10px] mt-[10px]"
                to="https://calendly.com/rohit-ip8t/30min"
                type="button"
              >
                get free consultation
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
