import React from "react";

interface TextInputProps {
  value?: string;
  onChange: (text: string) => void;
  className?: string;
  title: string;
  maxLength?: number;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  name?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  value,
  onChange,
  className,
  title,
  maxLength,
  placeholder,
  type,
  name,
}) => {
  return (
    <div className={`flex flex-col w-full mt-[13px] ${className}`}>
      <h1 className="text-pink text-sm">{title}</h1>
      <input
        className="text-black rounded-none w-full text-sm h-10 flex px-2 hover:border-[#ED3D67] focus:outline-none focus:border-[#ED3D67]  border-b-2"
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        maxLength={maxLength}
        onChange={(event) => {
          onChange?.(event.target.value);
        }}
      />
    </div>
  );
};

export default TextInput;
