const FormFooter = () => {
  return (
    <div className="w-full md:w-[40%] h-full mt-7 md:mt-0">
      <div className="flex flex-col justify-around">
        <div>
          <h1 className="font-inter font-medium text-[20px] leading-[24.2px]">
            HAVE A QUESTION?
          </h1>
          <a
            href="mailto:sales@outworldconcepts.com"
            className="font-Nunito font-normal text-[16px] leading-[24.5px]"
          >
            sales@outworldconcepts.com
          </a>
          <h1 className="font-inter font-medium text-[20px] leading-[24.2px] mt-5">
            LOOKING TO MAKE AN IMPACT?
          </h1>
          <a
            href="mailto:careers@outworldconcepts.com"
            className="font-Nunito font-normal text-[16px] leading-[24.5px]"
          >
            careers@outworldconcepts.com
          </a>
        </div>
        <div className="mt-10">
          <img className="rounded-2xl" src="images/image.png" alt="" />
          <h1 className="font-Nunito font-bold text-[18px] leading-[24.2px] mt-3">
            Mumbai
          </h1>
          <p className="font-Nunito font-normal text-[14px] leading-[24.5px]">
            We Work Chromium, CTS No. 106, 106/1-5, Jogeshwari-Vikhroli Link
            Road (JVLR), Near L&T Junction, Milind Nagar, Powai, Mumbai
            Maharashtra 400076 India
          </p>
        </div>
      </div>
    </div>
  );
};

export default FormFooter;
