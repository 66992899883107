function Client() {
  return (
    <div className="bg-white-pattern border-t-[3px] border-black">
      <div className=" container mx-auto   text-black  px-6 py-3  ">
        <div>
          <h2 className="lg:text-[50px] text-[40px] lg:leading-[60px] leading-[50px] sm:leading-[50px] md:text-[40px] fontNunito600 py-[40px]">
            Our <br /> Clients
          </h2>
        </div>
        <div className="grid gird-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-y-[60px] sm:gap-y-[40px] gap-y-[40px] gap-x-[48px] py-[20px] pb-[75px]">
          <div className="bg-[#FFFFFF] px-[25px] py-[35px] flex flex-col rounded-[5px]">
            <div>
              <img
                src="images/clients/curbwaste.png"
                alt="curbwaste"
                className="pb-[15px] h-[60px]"
              />
            </div>
            <div>
              <h2 className="fontNunito600 text-[18px] pt-[10px] ">
                A software for haulers by haulers. CurbWaste revolutionizes the
                waste management ecosystem through its cloud-based and
                data-driven cleantech platform.
              </h2>
            </div>
          </div>
          <div className="bg-[#FFFFFF] px-[25px] py-[35px] flex flex-col rounded-[5px]">
            <div>
              <img
                src="images/clients/d-hybrid.png"
                alt="curbwaste"
                className="pb-[15px] h-[60px]"
              />
            </div>
            <div>
              <h2 className="fontNunito600 text-[18px] pt-[10px]">
                D'hybrid is India's first men's-only fashion and grooming app,
                offering global mainstream brands, local hype labels and
                abundant personal care products to shop.
              </h2>
            </div>
          </div>
          <div className="bg-[#FFFFFF] px-[25px] py-[35px] flex flex-col rounded-[5px]">
            <div>
              <img
                src="images/clients/hotline.png"
                alt="curbwaste"
                className="pb-[15px] h-[60px]"
              />
            </div>
            <div>
              <h2 className="fontNunito600 text-[18px] pt-[10px]">
                Hotline is a place to connect with professionals/influencers
                across the globe. App comes with chat, audio call and video
                calls to allow 1:1 conversations.
              </h2>
            </div>
          </div>
          <div className="bg-[#FFFFFF] px-[25px] py-[35px] flex flex-col rounded-[5px]">
            <div>
              <img
                src="images/clients/aladdin.png"
                alt="curbwaste"
                className="pb-[15px] h-[60px]"
              />
            </div>
            <div>
              <h2 className="fontNunito600 text-[18px] pt-[10px]">
                A marketplace and customer community for telecom services in the
                UAE. The app helps customers discover, compare mobile, home
                services and data plans.
              </h2>
            </div>
          </div>
          <div className="bg-[#FFFFFF] px-[25px] py-[35px] flex flex-col rounded-[5px]">
            <div>
              <img
                src="images/clients/freshr.png"
                alt="curbwaste"
                className="pb-[15px] h-[60px]"
              />
            </div>
            <div>
              <h2 className="fontNunito600 text-[18px] pt-[10px]">
                A B2B-focused meat and seafood delivery startup. It's an
                ultimate platform for hotels, restaurants and Cafes to make
                wholesale purchases of quality fish and meat products in India.
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Client;
