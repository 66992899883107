import EmailForm from "../components/EmailForm";

function Contact() {
  return (
    <>
      <div className="m-auto ">
        <div className="container mx-auto flex justify-center px-6 mb-12 ">
          <h1 className="md:mr-0  text-[#FFFFFF] lg:mr-0  justify-center w-11/12 lg:mt-30 mt-20 text-[22px] lg:text-[35px] md:text-[30px] fontNunito600  font-bold  lg:leading-tight text-center">
            <span className="text-[#D4F049]">Talk to us! </span>
            We are nice people.
          </h1>
        </div>
        <img
          style={{ marginBottom: "-180px", borderRadius: "15px" }}
          className="mx-auto py-12 "
          src="images/contactUs.png"
        />
      </div>
      <div className="w-[100%] pb-[100px] flex items-center flex-col justify-center bg-white-pattern text-black ">
        <EmailForm />
      </div>
    </>
  );
}

export default Contact;
